import { DeviceLoader } from '@assets/gifs';
import Button from '@components/Button';
import useKeyPress, { KeyCode } from '@effects/useKeyPress';
import usePortal from '@effects/usePortal';
import { useWhyDidYouUpdate } from '@effects/useWhyDidYouUpdate';
import { BLANK_IMAGE } from '@utils/constants';
import MLALogger from '@utils/logger';
import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

export interface ModalProps {
    id: string;
    show: boolean;
    modalHeader?: React.ReactNode;
    modalFooter?: React.ReactNode;
    modalWarning?: React.ReactNode;
    ModalInnerClassName?: string;
    parentElementRef?: React.RefObject<HTMLElement>;
    children?: React.ReactNode;
    savedSignature?: string;
    onUseMySignatureClick?: () => void;
    onOutClick?: () => void;
    disableOutClick?: boolean;
    zIndex?: number;
    checkbox?: React.ReactNode;
    loader?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
    const {
        id,
        modalHeader,
        modalFooter,
        children,
        show,
        onOutClick,
        disableOutClick,
        modalWarning,
        zIndex,
        ModalInnerClassName,
        savedSignature,
        checkbox,
        onUseMySignatureClick,
        loader,
    } = props;
    MLALogger.LogCount(`[RenderCount] Modal - ${id}`);
    useWhyDidYouUpdate(`Modal - ${id}`, props);

    const target = usePortal(id);
    const rootNodeRef = useRef<HTMLDivElement>(null);
    const portalNodeRef = useRef<HTMLDivElement>(null);

    const escKey = useKeyPress(KeyCode.escape);

    useLayoutEffect(() => {
        if (onOutClick && escKey) {
            MLALogger.Log(['Modal'], 'Out Click Triggered via escKey');
            onOutClick();
        }
    }, [escKey, onOutClick]);

    useLayoutEffect(() => {
        const handler = (e: any) => {
            // TODO: Fix for incorrect captures
            if (onOutClick && !disableOutClick) {
                // Skip when we are clicking anything within the portal
                if (
                    e.target &&
                    typeof e.target.className === 'string' &&
                    e.target.className.split(' ').some((x: any) => x === 'Modal')
                ) {
                    MLALogger.Log(['Modal'], { message: 'Out Click Triggered', target: e.target, id, show });
                    if (show) {
                        onOutClick();
                        e.stopPropagation();
                    }
                }
            }
        };

        document.addEventListener('click', handler);
        document.addEventListener('touchstart', handler);

        return () => {
            document.removeEventListener('click', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [onOutClick, show, id, disableOutClick]);

    const [loadingContent, setLoadingContent] = useState<ReactNode>(null);
    useEffect(() => {
        if (show) {
            setLoadingContent(<LoadingContent />);
        } else {
            setLoadingContent(null);
        }
    }, [show]);

    return (
        <div ref={rootNodeRef}>
            {createPortal(
                <div ref={portalNodeRef}>
                    <style jsx>{`
                        @import 'vars';
                        @import 'utils';
                        @import 'mixins';
                        @import 'layout';

                        .Modal {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: rgba($color-black, 0.9);

                            width: 100vw;
                            height: 100vh;
                            position: fixed;
                            top: 0;

                            // IE-FIX
                            flex-direction: column;

                            @media (max-width: $sm-max) {
                                align-items: start;
                            }

                            :global(&--ScrollContainer) {
                                flex: 1 1 auto;
                                overflow-y: auto;
                                padding: grid(6);

                                border-radius: $border-radius;
                                @media (max-width: $sm-max) {
                                    max-width: 100vw;
                                    max-height: 100vh;
                                    padding-bottom: calc(
                                        70px + 56px + 50px
                                    ); // Height of footer + header + Possible bottom bar (iPhone / Android)
                                }
                            }

                            &--Inner {
                                display: flex;
                                flex-direction: column;
                                max-height: 90vh;
                                background-color: $color-white;
                                z-index: 2;

                                @media (max-width: $sm-max) {
                                    width: 100vw;
                                    height: 100vh;
                                    max-height: 100vh;
                                }

                                @media (min-width: $sm-max) {
                                    max-width: $form-input-maxwidth;
                                }

                                @media (min-width: $md-max) {
                                    min-width: 400px;
                                }

                                @media (prefers-color-scheme: dark) {
                                    background-color: darken($color-white, 80%);
                                }
                            }

                            z-index: ${zIndex ?? 9};

                            &--Checkbox {
                                display: flex;
                                flex-direction: column;
                                padding: grid(4) grid(6);
                            }

                            &--Header {
                                display: flex;
                                flex-direction: column;
                                padding: grid(4) grid(6);
                                border-bottom: 1px solid $color-border;
                                background-color: $color-grey96;
                                flex: 0 0 auto;
                                @include text-midbold();

                                &:empty {
                                    display: none;
                                }

                                .link {
                                    @include text-label();
                                }

                                @media (prefers-color-scheme: dark) {
                                    background-color: darken($color-grey96, 80%);
                                }
                            }

                            &--Warning {
                                width: 100%;
                                background-color: $color-error;
                                color: $color-white;
                                padding: grid(1) 0;
                                text-align: center;
                            }

                            &--Content {
                            }

                            &--Footer {
                                display: flex;
                                flex: 0 0 auto;
                                padding: grid(4) grid(6);
                                border-top: 1px solid $color-border;
                                box-shadow: 0 10px 40px -14px rgba(0, 0, 0, 0.25);

                                // Mobile Safari fix
                                @media (max-width: $sm-max) {
                                    position: fixed;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    background-color: $color-white;

                                    @media (prefers-color-scheme: dark) {
                                        background-color: darken($color-white, 80%);
                                    }
                                }
                            }
                        }

                        .savedSignatureContainer {
                            display: flex;
                            flex-direction: column;
                            -ms-flex: 0 0 auto;
                            position: relative;
                            width: 100%;
                            height: 100%;
                        }

                        .savedImg {
                            margin-bottom: grid(2);

                            max-height: grid(28);
                            // max-width: grid(130);
                        }
                        .label {
                            display: block;
                            margin-bottom: grid(2);
                            line-height: 24px;
                        }
                    `}</style>
                    {show && (
                        <div className="Modal">
                            <div className={!!ModalInnerClassName ? ModalInnerClassName : 'Modal--Inner'}>
                                {modalWarning && <div className="Modal--Warning">{modalWarning}</div>}
                                {modalHeader && <div className="Modal--Header">{modalHeader}</div>}
                                {!loader && (
                                    <RemoveScroll className="Modal--ScrollContainer">
                                        {React.Children.count(children) > 0 && (
                                            <div>
                                                {children}
                                                {savedSignature && savedSignature !== BLANK_IMAGE && (
                                                    <div className="savedSignatureContainer">
                                                        <div className="label">{'Saved Signature'}</div>
                                                        <img
                                                            className="savedImg"
                                                            src={savedSignature}
                                                            alt={'savedSignature'}
                                                        />
                                                        <div className="flex-row flex-end">
                                                            <Button
                                                                buttonType="link"
                                                                type="submit"
                                                                onClick={onUseMySignatureClick}
                                                            >
                                                                Use Saved Signature
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </RemoveScroll>
                                )}
                                {checkbox && <div className="Modal--Checkbox">{checkbox}</div>}
                                {modalFooter && <div className="Modal--Footer">{modalFooter}</div>}
                                {loader && (
                                    <div
                                        className="m-h-20 m-v20"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {loadingContent}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>,
                target
            )}
        </div>
    );
};

export default Modal;

const LoadingContent: React.FC = () => {
    return (
        <>
            <div
                className="flex-center-column m-t-20 m-b-40"
                style={{ width: '700px', textAlign: 'center' }}
            >
                <Loading />
                <div>
                    <Heading>Processing Your Request</Heading>
                    <p>This should only take a moment.</p>
                    <p>Please stay on this page and avoid closing your browser while we complete the process.</p>
                </div>
            </div>
        </>
    );
};

const LoadingSpinner: React.FC = () => {
    const [loaded, setLoaded] = useState(false);

    useLayoutEffect(() => {
        const img = new Image();
        img.src = DeviceLoader;
        img.onload = () => {
            setLoaded(true);
        };
    }, []);

    return (
        <div className="flex-center-row">
            <style jsx>{`
                .placeholder {
                    width: 200px;
                    height: 200px;
                }
            `}</style>
            {loaded ? (
                <img
                    width={200}
                    src={DeviceLoader}
                    alt="loading"
                />
            ) : (
                <div className={'placeholder'}></div>
            )}
        </div>
    );
};

const Loading = () => <LoadingSpinner />;

interface HeadingProps {
    children: ReactNode;
}

export const Heading: React.FC<HeadingProps> = ({ children }) => {
    return (
        <>
            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'utils';

                h1,
                h3 {
                    color: $color-secondary;
                    margin: grid(2) 0;
                    text-align: center;

                    @media (prefers-color-scheme: dark) {
                        color: lighten($color-link, 50%);
                    }
                }
            `}</style>
            <h1 className="text-header">{children}</h1>
        </>
    );
};
