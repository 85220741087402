import { DeviceTransferStatus } from '@containers/Consignments/DeviceTransferHelper';
import React, { createContext, ReactNode, useContext, useState } from 'react';

export type MovementActions = {
    // canAddOrEditDevicesForReceiver: boolean;
    isReceiver: boolean;
    canAddOrEditDevicesForProducer: boolean;
    canPerformMovement: boolean;
    canPreviewMovement: boolean;
    canViewMovement: boolean;
    isAccountAuthorizedForMovement: boolean;
    movementStatus: DeviceTransferStatus;
};

export interface MovementContextState {
    // String is the consignment.number
    movementActions?: Record<string, MovementActions>;
    setMovementActions: React.Dispatch<React.SetStateAction<Record<string, MovementActions> | undefined>>;
}
const defaultMovementContextState: MovementContextState = {
    movementActions: {}, // Empty record as default
    setMovementActions: () => {}, // No-op function as default
};

const MovementContext = createContext<MovementContextState>(defaultMovementContextState);

// Hook to access the context
export const useMovementContext = (): MovementContextState => {
    const context = useContext(MovementContext);
    if (!context) {
        throw new Error('useMovementContext must be used within a MovementProvider');
    }
    return context;
};

// Provider component
export const MovementProvider = ({ children }: { children: ReactNode }) => {
    const [movementActions, setMovementActions] = useState<Record<string, MovementActions>>();

    const value = {
        movementActions,
        setMovementActions,
    };

    return <MovementContext.Provider value={value}>{children}</MovementContext.Provider>;
};
