import {
    ChevronDownIcon,
    DateIcon,
    DeviceArrowRight,
    DeviceMinusIcon,
    DeviceTransferFailedIcon,
    DeviceTransferTransferredIcon,
    DocIcon,
    EditIcon,
    Print,
    ReviewIcon,
    ViewerIcon,
} from '@assets/icons';
import { DeviceTransferCreateIcon } from '@assets/icons';
import DeviceTransferNotReadyIcon from '@assets/icons/DeviceTransferNotReady';
import { useMovementContext } from '@common/context/MovementContext';
import { useUserState } from '@common/context/userContext';
import environment from '@common/relay/environment';
import Alert, { AlertType } from '@components/Alert';
import Button from '@components/Button';
import ContextMenu from '@components/Form/ContextMenu';
import Loader from '@components/Loader';
import NLISSupport from '@components/NLISSupportLink';
import { ProgressBar } from '@components/ProgressBar';
import TabBarItemIcon, { TTabBarItemIcon } from '@components/TabBar/TabBarItemIcon';
import Tag from '@components/Tag';
import Config from '@config';
import useMovement from '@effects/useMovement';
import useRoleValidation from '@effects/useRoleValidation';
import useWindowTitle from '@effects/useWindowTitle';
import { actionTypes, useGlobalState } from '@state';
import {
    CONSIGNMENT_SUBMITTED_MESAGE,
    CONSIGNMENT_SUBMITTED_TITLE,
    MOBILE_OR_TABLET_WIDTH,
    UNKNOWN_PIC,
} from '@utils/constants';
import { consignmentEnumToColor, consignmentEnumToName, ConsignmentStatus, UserRole } from '@utils/enum-transformers';
import { RoleTypeEnum, SectionName } from '@utils/enums';
import { getFormAlias } from '@utils/form-alias';
import { validateConsignment } from '@utils/validate-consignment';
import { toInteger } from 'lodash';
import _ from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'relay-hooks';
import { fetchQuery } from 'relay-runtime';

import { ConsignmentDetailQueryResponse } from './__generated__/ConsignmentDetailQuery.graphql';
import { AddBuyerModalRef } from './components/AddBuyerModal';
import AddViewerModal, { AddViewerModalRef } from './components/AddViewerModal';
import BuyerPanel from './components/BuyerPanel';
import { ConfirmModalRef } from './components/ConfirmModal';
import ConsignmentComment from './components/ConsignmentComment';
import DeleteConsignmentModal from './components/DeleteConsignmentModal';
import MovingToFromSummary from './components/MovingToFromSummary';
import getConsignmentContextMenu from './consignment-context-menu';
import { containsDeprecatedForms } from './ConsignmentHelper';
import { DeviceTransferStatus, ICON_COLORS, MovementCardDetail, MovementStatus } from './DeviceTransferHelper';
import MovementCard from './MovementCard';
import { ConsignmentUserMappingInput } from './mutations/__generated__/createUserMappingMutation.graphql';
import { cleanConsignmentPIC } from './mutations/createOrSaveConsignment';
import createUserMapping, { CreateUserMappingMutation } from './mutations/createUserMapping';
import OwnerInfo from './OwnerInfo';
import { ConsignmentEditQuery } from './queries/ConsignmentEdit';
import { ConsignmentUserMappingQuery } from './queries/ConsignmentUserMappingQuery';
import { GetDeviceMovementStatusQuery } from './queries/DeviceMovementStatusQuery';

const ConsignmentSummary: React.FC = () => {
    useWindowTitle('Consignment Summary');
    const { id } = useParams<any>();
    const history = useHistory();
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<any>(
        ConsignmentEditQuery,
        { id, envdAccountId },
        { fetchPolicy: 'network-only', fetchKey: id }
    );
    const { hasRole } = useRoleValidation();
    if (data) {
        const { consignment } = data;

        const canAccessComment = consignment?.status !== ConsignmentStatus.DRAFT && !hasRole(RoleTypeEnum.BUYER);
        const containsDeprecated = containsDeprecatedForms(consignment);
        // Save forms to the reducer
        //ENVDB-1233: if consignment contains deprecated forms then dont let user edit it
        if (consignment && !containsDeprecated) {
            // ENVDB-896: Making sure that destination pic is not AAAAAAAA
            let cloneConsignment = _.clone(consignment);
            if (consignment?.destination?.pic === UNKNOWN_PIC) {
                cloneConsignment = cleanConsignmentPIC(cloneConsignment, 'destination', '');
            }
            return (
                <>
                    <SummaryPage consignment={cloneConsignment} />
                    {canAccessComment && <ConsignmentComment status={consignment.status} />}
                </>
            );
        } else {
            history.replace('/consignments');
            return null;
        }
    } else {
        return (
            <Loader
                error={''}
                isLoading
                pastDelay={false}
                timedOut={false}
                retry={() => null}
            />
        );
    }
};

enum ActionType {
    EDIT,
    REVIEW,
}

export const SummaryPage: React.FC<{ consignment: ConsignmentDetailQueryResponse['consignment'] }> = ({
    consignment,
}) => {
    const history = useHistory();
    const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE_OR_TABLET_WIDTH });
    const [viewers, setViewers] = React.useState<ConsignmentUserMappingInput[]>([]);
    const [mutate] = useMutation(CreateUserMappingMutation);
    const viewerModalRef = useRef<AddViewerModalRef>();
    const { hasRole, hasAnyOfRoles, hasRoleWithConsignment } = useRoleValidation();
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const canEditViewers = hasRole(RoleTypeEnum.PRODUCER) && consignment?.status !== ConsignmentStatus.LOCKED;
    const canViewViewers = hasAnyOfRoles([
        RoleTypeEnum.PRODUCER,
        RoleTypeEnum.RECEIVER,
        RoleTypeEnum.TRANSPORTER,
        RoleTypeEnum.ELEVATEDVIEWER,
    ]);
    const viewersData = useQuery<any>(
        ConsignmentUserMappingQuery,
        { id: consignment?.number, role: UserRole.AUTHVIEWER, envdAccountId },
        { fetchPolicy: 'network-only', skip: !canViewViewers }
    );
    const deleteModalRef = useRef<ConfirmModalRef>();
    const deleteModalOject = {
        id: consignment?.id || '',
        type: consignment?.status,
        number: consignment?.number || '',
    };
    const [{ optionsState }, dispatch] = useGlobalState();
    const buyerModalRef = useRef<AddBuyerModalRef>();
    const [deviceMovementStatus, setDeviceMovementStatus] = useState(null);

    const canViewBuyerPanel =
        consignment?.status !== ConsignmentStatus.DRAFT &&
        hasAnyOfRoles([RoleTypeEnum.ELEVATEDVIEWER, RoleTypeEnum.BUYER, RoleTypeEnum.FACILITATOR]);

    const [movementCard, setMovementCard] = useState<MovementCardDetail>(null);
    const { movementActions } = useMovementContext();
    const { checkMovementActions } = useMovement();

    useEffect(() => {
        checkMovementActions(consignment, user);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consignment, user]);

    useEffect(() => {
        const ownerInfo =
            consignment?.owner?.name && consignment?.owner.pic
                ? `${consignment?.owner?.name} (${consignment.owner.pic})`
                : consignment?.owner?.pic || undefined;

        const hasDevices = (Number(consignment?.numOfAddedDevices) ?? 0) > 0;
        const movementCardDetail = getMovementCardDetail(ownerInfo, hasDevices, isTabletOrMobile);

        setMovementCard(movementCardDetail);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consignment, isTabletOrMobile, deviceMovementStatus]);

    const getMovementCardDetail = (
        ownerInfo: string | undefined,
        hasDevices: boolean,
        isTabletOrMobile: boolean
    ): MovementCardDetail | null => {
        const previewMovementDetails: MovementCardDetail = {
            title: 'Preview Upcoming Livestock Movement',
            buttonText: 'Preview NLIS Movement',
            description: (
                <>
                    <OwnerInfo text={ownerInfo} /> has added electronic identification device (eID) details to this
                    eNVD. You have seven days from the consignment's movement date to accept the livestock onto your
                    PIC, ensuring the transfer is completed in the NLIS database. However, it is your legal
                    responsibility to transfer all incoming livestock onto your PIC within 48 hours of their arrival.
                </>
            ),
            icon: (
                <DeviceTransferCreateIcon
                    color={ICON_COLORS.GREEN}
                    className="icon-xs"
                    alt="Preview NLIS Movement"
                />
            ),
            isTabletOrMobile,
            buttonAction: () => history.push(`/consignments/${consignment?.number}/movement`),
        };

        const viewMovementDetails: MovementCardDetail = {
            title: 'Track NLIS Movement Status',
            buttonText: 'View NLIS Devices',
            description: (
                <>
                    Monitor the details of your electronic identification device (eID), including NLIS ID (visual tag
                    ID) or RFID, by clicking 'View NLIS Devices.' This allows you to track whether the NLIS movement has
                    been completed to the next PIC. Note, after the movement date, edits aren't allowed, but you can
                    still view the receiver's actions.
                </>
            ),
            icon: (
                <DeviceTransferTransferredIcon
                    color={ICON_COLORS.GREEN}
                    className="icon-xs"
                    alt="Devices added"
                />
            ),
            isTabletOrMobile,
            buttonAction: () => history.push(`/consignments/${consignment?.number}/movement`),
        };

        const createMovementLpaReceiver: MovementCardDetail = {
            title: 'Create NLIS Movement (optional)',
            buttonText: 'View Details',
            description: (
                <>
                    <OwnerInfo text={ownerInfo} /> has added devices such as NLISIDs and RFID ear tags to this
                    consignment, allowing you to move them onto your PIC on the NLIS database.
                </>
            ),
            icon: (
                <DeviceTransferCreateIcon
                    color={ICON_COLORS.GREEN}
                    className="icon-xs"
                    alt="Ready with devices"
                />
            ),
            isTabletOrMobile,
            buttonAction: () => history.push(`/consignments/${consignment?.number}/movement`),
        };

        const addDevices: MovementCardDetail = {
            buttonText: 'Add NLIS Devices',
            title: 'Start NLIS Movement Off Your Property (Optional)',
            description: (
                <>
                    You can now provide electronic identification device (eID) details such as NLISID (visual tag ID) or
                    RFID, to streamline the property-to-property transfer with your receiver. If you have your NLIS
                    device details ready before the movement date, add them to the consignment.
                </>
            ),
            icon: (
                <DeviceTransferNotReadyIcon
                    color={ICON_COLORS.GREY}
                    className="icon-xs"
                    alt="Add NLIS devices"
                />
            ),
            isTabletOrMobile,
            buttonAction: () => history.push(`/consignments/${consignment?.number}/movement/device-upload`),
        };

        const devicesAdded: MovementCardDetail = {
            buttonText: 'View NLIS Devices',
            title: 'NLIS Devices Added',
            description: (
                <>
                    The details of your electronic identification device (eID) details such as NLISID (visual tag ID) or
                    RFID have been successfully added to your consignment and will be visible to the receiver once the
                    consignment is submitted. Click 'View NLIS devices' to review, remove, or edit the list. You can
                    make changes until the movement date.
                </>
            ),
            icon: (
                <DeviceTransferTransferredIcon
                    color={ICON_COLORS.GREEN}
                    className="icon-xs"
                    alt="Devices added"
                />
            ),
            isTabletOrMobile,
            buttonAction: () => history.push(`/consignments/${consignment?.number}/movement/`),
        };

        const movementPending: MovementCardDetail = {
            buttonText: 'Refresh to view',
            title: 'NLIS Movement Pending',
            description: (
                <>
                    This transaction has been submitted, in case this takes more time than expected, contact{' '}
                    <NLISSupport />
                </>
            ),
            icon: (
                <DeviceArrowRight
                    color={ICON_COLORS.GREEN}
                    className="icon-xs"
                    alt="Device Transfer Pending"
                />
            ),
            isTabletOrMobile,
            buttonAction: async () => {
                const consignmentNumber = consignment?.number;
                const response = await fetchQuery(environment, GetDeviceMovementStatusQuery, {
                    consignmentNumber,
                    envdAccountId,
                });

                if (response) {
                    setDeviceMovementStatus((response as any).deviceMovementStatus);
                    history.push(`/consignments/summary/${consignment?.number}`);
                }
            },
        };

        const getMovementStatusDetails = (
            status: MovementStatus | undefined
        ): {
            title: string;
            description: string;
            icon: JSX.Element | null;
        } => {
            switch (status) {
                case MovementStatus.Warning:
                    return {
                        title: 'NLIS Movement Contains Warnings',
                        description:
                            'This NLIS movement onto your PIC was recorded in NLIS and the detail are now available for viewing.',
                        icon: (
                            <DeviceTransferTransferredIcon
                                color={ICON_COLORS.YELLOW}
                                className="icon-xs"
                                alt="Device Transfer Complete with Warnings"
                            />
                        ),
                    };

                case MovementStatus.Error:
                    return {
                        title: 'NLIS Movement Contains Errors',
                        description:
                            'This NLIS movement onto your PIC was recorded in NLIS and the detail are now available for viewing.',
                        icon: (
                            <DeviceMinusIcon
                                color={ICON_COLORS.RED}
                                className="icon-xs"
                                alt="Device Transfer Complete with Errors"
                            />
                        ),
                    };

                default:
                    return {
                        title: 'NLIS Movement Complete',
                        description:
                            'This NLIS movement onto your PIC was successfully recorded in NLIS and the detail are now available for viewing.',
                        icon: (
                            <DeviceTransferTransferredIcon
                                color={ICON_COLORS.GREEN}
                                className="icon-xs"
                                alt="Device Transfer Complete"
                            />
                        ),
                    };
            }
        };

        const movementComplete: MovementCardDetail = {
            buttonText: 'View Details',
            ...getMovementStatusDetails(consignment?.deviceMovementStatus as MovementStatus),
            isTabletOrMobile,
            buttonAction: () => history.push(`/consignments/${consignment?.number}/movement/`),
        };

        const createMovementWithoutDevices: MovementCardDetail = {
            buttonText: 'Create NLIS movement',
            title: 'Create NLIS Movement Onto Your Property',
            description: (
                <>
                    To complete your NLIS movement onto your property, add the incoming livestock's electronic
                    identification device (eID) details, either the NLISID (visual tag ID) or RFID.
                </>
            ),
            icon: (
                <DeviceTransferNotReadyIcon
                    color={ICON_COLORS.GREY}
                    className="icon-xs"
                    alt="Ready without devices"
                />
            ),
            buttonAction: () => history.push(`/consignments/${consignment?.number}/movement/`),
        };

        const createMovementWithDevices: MovementCardDetail = {
            buttonText: 'Complete NLIS movement',
            title: 'Complete NLIS Livestock Movement Onto Your Property',
            description: (
                <>
                    Electronic identification (eID) details have been added to this eNVD. You have seven days from the
                    consignments movement date to confirm that the devices added match the incoming livestock's eIDs.
                    Once confirmed, complete the NLIS transfer and moving them onto your PIC.
                </>
            ),
            icon: (
                <DeviceTransferCreateIcon
                    color={ICON_COLORS.GREEN}
                    className="icon-xs"
                    alt="Ready with devices"
                />
            ),
            buttonAction: () => history.push(`/consignments/${consignment?.number}/movement/`),
        };

        const notAvailable: MovementCardDetail = {
            buttonText: 'Login to NLIS',
            title: 'The NLIS Movement Is No Longer Available On eNVD',
            description: (
                <>
                    The deadline for creating this movement in eNVD has passed (movement day + 7 days). However, you can
                    still manage this through the NLIS database. We recommend logging into the NLIS system to transfer
                    all NLIS devices and maintain lifetime traceability.
                </>
            ),
            icon: (
                <DeviceTransferFailedIcon
                    color={ICON_COLORS.RED}
                    className="icon-xs"
                    alt="Not available"
                />
            ),
            buttonAction: () => {
                if (window) {
                    window.open(Config.NLIS_URL, '_blank');
                }
            },
        };

        if (!consignment || !consignment.number) return null;

        const deviceMovementStatus = consignment.deviceMovementStatus;

        if (deviceMovementStatus === MovementStatus.Pending) {
            return movementPending;
        }

        if (
            deviceMovementStatus === MovementStatus.Complete ||
            deviceMovementStatus === MovementStatus.Error ||
            deviceMovementStatus === MovementStatus.Warning
        ) {
            return movementComplete;
        }

        const actions = movementActions && movementActions[consignment.number];

        if (!actions) {
            checkMovementActions(consignment, user!);
        }

        if (actions) {
            if (actions.movementStatus === DeviceTransferStatus.NotAvailable) {
                return notAvailable;
            }

            if (actions.canPerformMovement) {
                return hasDevices ? createMovementWithDevices : createMovementWithoutDevices;
            }

            if (actions.canPreviewMovement) {
                return previewMovementDetails;
            }

            if (actions.canAddOrEditDevicesForProducer && Number(consignment.numOfAddedDevices)) {
                return devicesAdded;
            }

            if (actions.canAddOrEditDevicesForProducer) {
                return addDevices;
            }

            if (actions.canViewMovement && actions.isReceiver && !actions.isAccountAuthorizedForMovement) {
                return createMovementLpaReceiver;
            }

            if (actions.canViewMovement) {
                return viewMovementDetails;
            }
        }

        return null;
    };

    useEffect(() => {
        setViewers(viewersData?.data?.consignmentUserMapping ?? []);
    }, [viewersData]);

    useLayoutEffect(() => {
        if (optionsState.isOpenViewersModal) {
            viewerModalRef.current?.show();
            dispatch({ type: actionTypes.generic.resetOptionStateAction });
        }
        if (optionsState.isScrollToCommentSection) {
            window.scrollTo(0, document.body.scrollHeight);
            dispatch({ type: actionTypes.generic.resetOptionStateAction });
        }
    }, [optionsState, dispatch]);

    const getAddress = (address: any) => {
        let formattedAddress =
            formatAddressSection(address?.line1) +
            formatAddressSection(address?.town) +
            formatAddressSection(address?.state) +
            formatAddressSection(address?.postcode);
        formattedAddress = formattedAddress.trim();
        if (formattedAddress && formattedAddress.charAt(0) === ',') {
            return formattedAddress.substring(1).trim();
        }
    };

    const formatAddressSection = (section: any) => {
        if (section) {
            return ', ' + section;
        }
        return '';
    };

    const getSectionAction = (
        sectionName: SectionName,
        status: ConsignmentStatus | undefined | any
    ): string | ActionType => {
        switch (status) {
            case ConsignmentStatus.DRAFT:
                return hasRole(RoleTypeEnum.PRODUCER) ? ActionType.EDIT : '';
            case ConsignmentStatus.LOCKED:
                return ActionType.REVIEW;
        }
        if (hasRoleWithConsignment(user.accountDetails, RoleTypeEnum.PRODUCER, consignment)) {
            return [SectionName.MOVEMENT, SectionName.LIVESTOCK_DESCRIPTION, SectionName.TRANSPORTER].indexOf(
                sectionName
            ) > -1
                ? ActionType.EDIT
                : ActionType.REVIEW;
        }
        if (hasRole(RoleTypeEnum.TRANSPORTER)) {
            return SectionName.TRANSPORTER === sectionName ? ActionType.EDIT : ActionType.REVIEW;
        }
        return ActionType.REVIEW;
    };

    const stringToRelativeTime = (str?: string) => (str && new Date(str)?.relativeTime()) || 'never';
    let consignmentPDFurl = consignment?.pdfUrl?.startsWith('http')
        ? consignment?.pdfUrl
        : Config.BASE_GRAPHQL_SERVER_URL + '/' + consignment?.pdfUrl;

    let headers: any = [
        {
            title: 'Movement',
            section: SectionName.MOVEMENT,
        },
        {
            title: 'Forms',
            section: SectionName.FORMS,
        },
        {
            title: 'Livestock',
            section: SectionName.LIVESTOCK_DESCRIPTION,
        },
        {
            title: 'History',
            section: SectionName.LIVESTOCK_HISTORY,
        },
        {
            title: 'Food Safety',
            section: SectionName.FOOD_SAFETY,
        },
        {
            title: 'Chemical / Treatments',
            section: SectionName.CHEMICAL_AND_TREAMENT_HISTORY,
        },
        {
            title: 'Declaration',
            section: SectionName.DECLARATION,
        },
        {
            title: 'Transporter',
            section: SectionName.TRANSPORTER,
        },
    ];

    const getIcon = (valid: any): TTabBarItemIcon | undefined => {
        if (valid === undefined) {
            return undefined;
        }
        return valid ? 'success' : 'error';
    };

    headers = headers.map((val: any) => {
        const valOb = validateConsignment(consignment, val.section);
        const icon = getIcon(valOb.valid);
        return {
            ...val,
            icon,
            numOfQues: valOb.numOfQues,
            numOfAns: valOb.numOfAns,
            completed: toInteger((valOb.numOfAns * 100) / valOb.numOfQues),
        };
    });

    const updateViewers = (viewers: ConsignmentUserMappingInput[]) => {
        setViewers(viewers);
    };

    const deleteViewer = (mappingId: string) => {
        let tempViewers = [...viewers];
        tempViewers = tempViewers
            .filter((viewer) => Number(viewer.mappingId) !== Number(mappingId))
            .map((val: ConsignmentUserMappingInput) => {
                return {
                    mappingId: val.mappingId,
                    email: val.email,
                    consignmentNumber: val.consignmentNumber,
                    role: val.role,
                };
            });
        const response = createUserMapping(
            mutate,
            { consignmentNumber: consignment?.number ?? '', role: UserRole.AUTHVIEWER, mappings: tempViewers ?? [] },
            envdAccountId
        );
        response.then((mappings) => {
            console.log(mappings);
            setViewers(mappings);
        });
    };

    return (
        <>
            <div className="vertical-layout flex-column m-t-80">
                <div className="summary-header flex-row p-t-16 ">
                    <div className="doc-icon flex-center-row">
                        <DocIcon className="list-icon" />
                    </div>
                    <div
                        className="consignment-details"
                        data-cy="consignment-summary"
                    >
                        <div className="flex-row">
                            <h2 className="m-r-16">{consignment?.number}</h2>
                            <Tag
                                tagType={consignmentEnumToColor(consignment?.status as ConsignmentStatus)}
                                text={consignmentEnumToName(consignment?.status as ConsignmentStatus)}
                            />
                        </div>
                        <div className="small muted">
                            Last updated about {stringToRelativeTime(consignment?.updatedAt as string)}
                        </div>
                    </div>
                    <div className="options-btn">
                        <ContextMenu
                            options={getConsignmentContextMenu(
                                { ...consignment, type: 'CONSIGNMENT' } as any,
                                user,
                                deleteModalRef,
                                undefined,
                                true,
                                history,
                                viewerModalRef,
                                buyerModalRef
                            )}
                            icon={<ChevronDownIcon />}
                            buttonText="Options"
                            buttonType="primary"
                            buttonSize="normal"
                        />
                    </div>
                    <DeleteConsignmentModal
                        consignment={{ ...deleteModalOject, type: 'CONSIGNMENT' }}
                        ref={deleteModalRef}
                    />
                </div>
                <div className="details-panel flex-row">
                    <div className="owner-panel box">
                        <div className="panel-heading p-v-16 p-h-16">
                            <h2>Livestock Owner</h2>
                        </div>
                        <hr />
                        <div className="panel-body p-v-32 p-h-32 flex-column">
                            <div className="group flex-column">
                                <h3 className="m-b-8">Owner Name</h3>
                                <p>{consignment?.owner?.name}</p>
                            </div>
                            <div className="group flex-column">
                                <h3 className="m-b-8">Owner PIC</h3>
                                <p>{consignment?.owner?.pic}</p>
                            </div>
                            <div className="group flex-column">
                                <h3 className="m-b-8">Owner Address</h3>
                                <p>{getAddress(consignment?.owner?.address)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="summary-panel box">
                        <div className="panel-heading flex-row flex-between p-v-16 p-h-16">
                            <h2>Consignment Summary</h2>
                            {consignment?.movementDate ? (
                                <div className="date-info flex-row ">
                                    <DateIcon />
                                    <label
                                        htmlFor=""
                                        className="info-label grey-text"
                                    >
                                        Movement Date
                                    </label>
                                    {consignment?.movementDate
                                        ? new Date(consignment?.movementDate as any).toMLADateString()
                                        : ''}
                                    {consignment?.movementTime ? `, ${consignment?.movementTime.convertToAMPM()}` : ''}
                                </div>
                            ) : null}
                        </div>
                        <hr />
                        <MovingToFromSummary consignment={consignment} />
                        <div className="forms-info p-v-16 p-h-16">
                            <h2>Included forms</h2>
                            <div className="FormsSection--Forms flex-row m-v-8">
                                {consignment?.forms?.map((x: any) => {
                                    const fa = getFormAlias(x.type);
                                    if (fa) {
                                        return (
                                            <div
                                                key={fa.program}
                                                className="flex-center-row"
                                            >
                                                <img
                                                    src={fa.image}
                                                    className="Documents--img m-r-8"
                                                    alt="Logo"
                                                />
                                                <span>
                                                    {fa.alias} {x.serialNumber && <span>({x.serialNumber})</span>}
                                                </span>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                {movementCard && (
                    <MovementCard
                        title={movementCard.title!}
                        description={movementCard.description!}
                        icon={movementCard.icon}
                        buttonText={movementCard.buttonText!}
                        buttonAction={movementCard.buttonAction}
                        isTabletOrMobile={isTabletOrMobile}
                    />
                )}

                <div className="consignment-details-panel flex-row">
                    <div className="form-questions-panel flex-column box">
                        <div className="panel-heading flex-row flex-between p-v-16 p-h-16">
                            <h2>Consignment Form Questions</h2>
                            <Button
                                className="print-button"
                                buttonType="secondary"
                                buttonSize={isTabletOrMobile ? 'small' : 'normal'}
                                onClick={() => window.open(consignmentPDFurl, 'allForms')}
                            >
                                {consignment?.status === ConsignmentStatus.SUBMITTED && <Print />}
                                {'View eNVD'}
                            </Button>
                        </div>
                        {consignment?.status === ConsignmentStatus.SUBMITTED && (
                            <Alert
                                type={AlertType.Info}
                                classNames="alert m-h-16"
                                title={CONSIGNMENT_SUBMITTED_TITLE}
                                subtitle={<>{CONSIGNMENT_SUBMITTED_MESAGE}</>}
                            />
                        )}
                        {headers.map((header: any) => {
                            return (
                                // ENVDB-1050: if any section is disabled or have no questions in it, we ll not show that section in the tab bar
                                header.numOfQues > 0 && (
                                    <div
                                        key={header.title}
                                        className="section flex-row box m-v-16 m-h-16 p-v-16"
                                    >
                                        <div className="icon flex-center-row">
                                            <TabBarItemIcon icon={header.icon} />
                                        </div>
                                        <div className="section-info flex-column">
                                            <h4 className="m-b-12">{header.title}</h4>
                                            <div className="progress-bar flex-column">
                                                <ProgressBar completed={header.completed}></ProgressBar>
                                                <div className="small grey-text m-t-4">
                                                    {header.numOfAns} of {header.numOfQues} questions completed
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section-edit-link flex-row p-v-16 p-h-16">
                                            <Button
                                                buttonType="link"
                                                onClick={() => {
                                                    history.push(
                                                        `/consignments/edit/${consignment?.number}/${header.section}`
                                                    );
                                                }}
                                            >
                                                {getSectionAction(header.section, consignment?.status) ===
                                                ActionType.EDIT ? (
                                                    <>
                                                        <label className="small grey-text m-r-8">Edit Section</label>
                                                        <EditIcon className="edit-icon" />
                                                    </>
                                                ) : (
                                                    <>
                                                        <label className="small grey-text m-r-8">Review Section</label>
                                                        <ReviewIcon className="edit-icon" />
                                                    </>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                )
                            );
                        })}
                    </div>

                    <div className="roles-panel">
                        {canViewBuyerPanel && (
                            <BuyerPanel
                                consignmentNumber={consignment?.number || ''}
                                buyerModalRef={buyerModalRef}
                                status={consignment?.status || ''}
                                envdAccountId={envdAccountId}
                            />
                        )}
                        {canViewViewers && (
                            <div className="viewer-panel box">
                                <div className="panel-heading flex-row flex-between p-v-16 p-h-16">
                                    <div className="flex-center-row">
                                        <h2>Consignment Viewers</h2>
                                    </div>
                                    {canEditViewers && (
                                        <Button
                                            className="print-button"
                                            buttonType="secondary"
                                            buttonSize={isTabletOrMobile ? 'small' : 'normal'}
                                            onClick={() => viewerModalRef.current?.show()}
                                        >
                                            Add Viewer
                                        </Button>
                                    )}
                                    <AddViewerModal
                                        ref={viewerModalRef}
                                        viewers={viewers ?? []}
                                        updateViewers={(updatedViewers: ConsignmentUserMappingInput[]) =>
                                            updateViewers(updatedViewers)
                                        }
                                        consignmentNumber={consignment?.number}
                                    ></AddViewerModal>
                                </div>
                                <div className=" flex-column">
                                    {viewers?.map((viewer: any) => {
                                        return (
                                            <div
                                                key={viewer.mappingId}
                                                className="viewer-details flex-row p-v-16"
                                            >
                                                <div className="viewer-icon flex-center-row p-v-16 p-h-16">
                                                    <ViewerIcon />
                                                </div>
                                                <div className="flex-column viewer-name">
                                                    <h4 className="">
                                                        {viewer.firstName}&nbsp;{viewer.lastName}
                                                    </h4>
                                                    <div className="flex-column">
                                                        <div className="small grey-text m-t-4">{viewer.email}</div>
                                                    </div>
                                                </div>
                                                {canEditViewers && (
                                                    <div className="flex-row p-h-16">
                                                        <Button
                                                            buttonType="link"
                                                            onClick={() => {
                                                                deleteViewer(viewer.mappingId);
                                                            }}
                                                        >
                                                            <label className="small remove-text m-r-8">Remove</label>
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'layout';

                .doc-icon,
                .grey-text {
                    color: $color-subtitle;
                    cursor: inherit;
                }

                .remove-text {
                    color: $color-error;
                    cursor: inherit;
                }

                .summary-header,
                .FormsSection--Forms {
                    gap: 20px;
                    flex-wrap: wrap;
                }

                :global(.Documents--img) {
                    width: 24px;
                    max-height: 30px;
                }

                :global(.alert) {
                    background-color: $color-grey14 !important;
                    color: $color-white !important;
                }

                :global(.alert .small) {
                    color: $color-white !important;
                }

                :global(.list-icon) {
                    width: 20px;
                    height: 30px;
                    color: $isc-blue;
                }

                .viewer-icon {
                    :global(svg) {
                        width: 36px;
                        height: 36px;
                    }
                }

                :global(.print-button) {
                    border-color: $isc-blue !important;
                    width: max-content;
                    &:hover {
                        background-color: $isc-blue !important;
                    }
                }

                .section {
                    .icon {
                        min-width: calc(max(50px, 4vw));
                        justify-content: center;
                    }

                    .section-info {
                        flex: auto;
                        justify-content: space-evenly;
                    }

                    .progress-bar {
                        max-width: calc(min(280px, 30vw));
                    }

                    .section-edit-link {
                        align-items: center;
                    }
                }

                :global(.edit-icon) {
                    color: #848a94;
                }

                .vertical-layout {
                    gap: 20px;
                }

                hr {
                    background: $grey-border;
                    border-bottom: 1px solid $grey-border;
                }

                .details-panel {
                    gap: 20px;
                    flex-wrap: wrap;
                }

                .owner-panel {
                    flex: 1 0 30%;
                }

                .panel-body {
                    gap: 20px;
                    overflow-wrap: anywhere;
                    @media (max-width: $lg-max) {
                        flex-wrap: wrap;
                    }
                }

                .summary-panel {
                    flex: 2 0 65%;

                    .date-info {
                        gap: 10px;
                    }

                    .to-details {
                        border-left: 1px solid $grey-border;
                        position: relative;
                    }
                }

                .livestock-details {
                    flex: 1 0 30%;
                }

                .box {
                    box-sizing: border-box;
                    background-color: $color-white;
                    border: 1px solid $grey-border;
                    border-radius: 4px;
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .options-btn {
                    margin-left: auto;
                    @media (max-width: $form-input-maxwidth) {
                        margin-left: initial;
                    }
                }

                .consignment-details-panel {
                    gap: 20px;
                    flex-wrap: wrap-reverse;
                    & .form-questions-panel {
                        flex: 1 0 55%;
                        @media (max-width: $md-max) {
                            min-width: 100%;
                        }
                    }

                    & .roles-panel {
                        flex: 1 0 40%;

                        & .viewer-details {
                            border-top: 1px solid $grey-border;

                            & .viewer-name {
                                flex: auto;
                                justify-content: center;
                            }
                        }
                    }
                }
            `}</style>
        </>
    );
};

export default ConsignmentSummary;
