import 'jspdf-autotable';

import { useDevicesContext, ValidatedDevice } from '@common/context/DevicesContext';
import { useUserState } from '@common/context/userContext';
import deviceMovementPropertyToProperty, {
    deviceMovementPropertyToPropertyMutation,
} from '@containers/Consignments/mutations/deviceMovementPropertyToProperty';
import SaveOrUpdateDevices from '@containers/Consignments/mutations/saveOrUpdateDevices';
import { MAX_UPLOAD_DEVICES } from '@utils/constants';
import { ValidationApiAction } from '@utils/enums';
import { handleFileRead } from '@utils/file-ops';
import { formatISO } from 'date-fns';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import { createRef, useCallback, useState } from 'react';
import { DropzoneRef, ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';
import { useMutation } from 'relay-hooks';

interface jsPDFWithAutoTable extends jsPDF {
    autoTable: (options: any) => jsPDF;
}

export enum CustomErrorCode {
    DeviceLimitExceeded = 'TagLimitExceeded', // Custom error for exceeding tag limit
}

const DeviceErrorCode = {
    ...ErrorCode,
    ...CustomErrorCode,
} as const;

// type DeviceErrorCodeType = keyof typeof DeviceErrorCode;
type DeviceErrorCodeType = CustomErrorCode | ErrorCode;
/**
 * Custom hook to manage files from react-dropzone
 * @returns {Object} An object containing the files, dropzoneProps, and utility functions
 */
const useDeviceManager = () => {
    const {
        acceptedFile,
        setAcceptedFile,
        deviceIds,
        setDeviceIds,
        isApiError,
        setIsApiError,
        uploadErrorMessage,
        setUploadErrorMessage,
        validatedDevices,
        setValidatedDevices,
    } = useDevicesContext();
    const [rawManualDeviceIds, setRawManualDeviceIds] = useState<string>('');
    const [inDropZone, setInDropZone] = useState(false);

    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const dropzoneRef = createRef<DropzoneRef>();

    const [mutate] = useMutation(deviceMovementPropertyToPropertyMutation);

    const openDialog = () => {
        if (dropzoneRef.current && (acceptedFile.length === 0 || uploadErrorMessage.length > 0)) {
            dropzoneRef.current.open();
        }
    };

    const onDropAccepted = () => setInDropZone(false);
    const onDragOver = () => setInDropZone(true);
    const onDragLeave = () => setInDropZone(false);

    const processFile = async (acceptedFiles: FileWithPath[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setAcceptedFile(acceptedFiles);
            setUploadErrorMessage('');

            handleFileRead(
                acceptedFiles[0], // Pass the file
                (values) => {
                    if (values.length > MAX_UPLOAD_DEVICES) {
                        const errMessage = getErrorMsg(DeviceErrorCode.FileTooLarge);

                        setUploadErrorMessage(errMessage);

                        return;
                    }

                    setDeviceIds(new Set(values)); // Update state with the values
                    setUploadErrorMessage(''); // Clear any previous errors
                },
                (err) => {
                    setUploadErrorMessage(err); // Handle any errors
                }
            );
        }
    };

    const getErrorMsg = (errorCode: DeviceErrorCodeType, manualDeviceId?: string) => {
        switch (errorCode) {
            case DeviceErrorCode.FileInvalidType:
                return `Upload failed: Unsupported file format. Please use a CSV or TXT file instead.`;
            case DeviceErrorCode.FileTooLarge:
                return `Upload failed: File size exceeds the ${MAX_UPLOAD_DEVICES.toLocaleString()} records. Please upload a smaller file.`;
            case DeviceErrorCode.DeviceLimitExceeded:
                return `The 'Add manually' option has reached its maximum limit of 1,000 NLIS devices. To proceed, you can either remove some devices or select the 'Upload your file' option, which allows you to add more NLIS devices.`;
            default:
                return `File upload failed: There is some error with this file, please upload another file.`;
        }
    };

    const onDropRejected = (fileRejections: FileRejection[]) => {
        setInDropZone(false);
        if (fileRejections.length) {
            const { errors } = fileRejections[0];
            const errMsg = getErrorMsg(errors[0].code as DeviceErrorCodeType);
            setUploadErrorMessage(errMsg);
        }
    };

    // Utility function to clear all files
    const clearFiles = useCallback(() => {
        setAcceptedFile([]);
        setDeviceIds(new Set());
        setRawManualDeviceIds('');
        setUploadErrorMessage('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearValidatedDevices = useCallback(() => {
        setValidatedDevices({
            value: new Set<ValidatedDevice>(),
            totalDevices: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const processManualDevices = (manualDeviceIDs?: string) => {
        if (!manualDeviceIDs) {
            setRawManualDeviceIds('');
            setDeviceIds(new Set());
            setUploadErrorMessage('');
            return;
        }
        setRawManualDeviceIds(manualDeviceIDs);
        const lines = manualDeviceIDs
            .split('\n')
            .map((line) => line.trim())
            .filter(Boolean); // remove empty lines

        const newTags = new Set<string>();
        let errMsg = '';
        for (let i = 0; i < lines.length; i++) {
            const trimmedTag = lines[i]; // Use the whole line as one device ID
            if (trimmedTag) {
                newTags.add(trimmedTag);
                if (newTags.size > 1000) {
                    errMsg = getErrorMsg(DeviceErrorCode.DeviceLimitExceeded, trimmedTag);
                    break;
                }
            }
        }
        setDeviceIds(newTags);
        setUploadErrorMessage(errMsg);
    };

    const transferDevices = async (consignmentNumber: string) => {
        try {
            return await deviceMovementPropertyToProperty(
                mutate,
                {
                    consignmentNumber: consignmentNumber,
                    deviceMovementDate: formatISO(new Date(), { format: 'extended' }),
                },
                envdAccountId
            );
        } catch (error) {
            setIsApiError(true);
            throw error;
        }
    };

    const revalidate = async (consignmentNumber: string) => {
        const deviceIds = Array.from(validatedDevices.value).map((device) => device.nLISID);

        return await validateAndAddDevices(consignmentNumber, ValidationApiAction.ADD, deviceIds, undefined, true);
    };

    const getPayload = (
        action: ValidationApiAction,
        consignmentNumber: string,
        newDeviceIds?: Array<string>,
        deviceIdsToBeRemoved?: Array<string>,
        isRevalidate: boolean = false
    ) => {
        switch (action) {
            case ValidationApiAction.ADD:
                return {
                    number: consignmentNumber,
                    action: action,
                    deviceIds: isRevalidate ? newDeviceIds : Array.from(deviceIds),
                };
            case ValidationApiAction.EDIT:
                return {
                    number: consignmentNumber,
                    action: action,
                    deviceIds: newDeviceIds,
                    deviceIdsToBeRemoved: deviceIdsToBeRemoved,
                };
            case ValidationApiAction.DELETE:
                return {
                    number: consignmentNumber,
                    action: action,
                    deviceIdsToBeRemoved: deviceIdsToBeRemoved,
                };
            default:
                return {
                    number: consignmentNumber,
                    action: action,
                    deviceIds: Array.from(deviceIds),
                };
        }
    };

    const validateAndAddDevices = async (
        consignmentNumber: string,
        action: ValidationApiAction,
        newDeviceIds?: Array<string>,
        deviceIdsToBeRemoved?: Array<string>,
        isRevalidate: boolean = false
    ) => {
        try {
            // if (deviceIds.size > 0) {
            const payload = getPayload(action, consignmentNumber, newDeviceIds, deviceIdsToBeRemoved, isRevalidate);
            const response = await SaveOrUpdateDevices(payload, envdAccountId, user.token);
            if (response?.value) {
                setValidatedDevices({
                    value: new Set<ValidatedDevice>(response.value),
                    totalDevices: Number(response.totalDevices) ?? 0,
                });
                clearFiles();
            }
            // }
        } catch (error) {
            setIsApiError(true);
        }
    };

    const exportToPDF = (consignmentNumber: string) => {
        const doc = new jsPDF() as jsPDFWithAutoTable;
        const data = Array.from(validatedDevices.value);

        doc.autoTable({
            head: [['NLISID', 'RFID', 'Livestock', 'Status', 'Deceased']],
            body: data.map((device) => [
                device.nLISID,
                device.rFID,
                device.species,
                device.status,
                device.deceased ? 'YES' : 'NO',
            ]),
            headStyles: {
                fillColor: [0, 0, 0], // Black background
                textColor: [255, 255, 255], // White text
                fontStyle: 'bold',
            },
            didDrawPage: (data: any) => {
                // Get actual number of pages after table is drawn
                const str = `Page ${data.pageNumber}`;
                doc.setFontSize(10);
                doc.text(str, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, {
                    align: 'center',
                });
            },
        });
        doc.save(`${consignmentNumber}-Devices.PDF`);
    };

    const exportToCSV = (consignmentNumber: string) => {
        const csv = Papa.unparse({
            fields: ['NLISID', 'RFID', 'LIVESTOCK', 'STATUS', 'DECEASED'],
            data: Array.from(validatedDevices.value).map((device) => [
                device.nLISID,
                device.rFID,
                device.species,
                device.status,
                device.deceased ? 'YES' : 'NO',
            ]),
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute('download', `${consignmentNumber}-Devices.CSV`);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const exportToTXT = (consignmentNumber: string) => {
        // Define column widths
        const columnWidths = {
            nlisid: 20, // Adjust these numbers based on your needs
            rfid: 20,
            species: 15,
            status: 10,
            deceased: 5,
        };

        // Function to pad strings to fixed width
        const padString = (str: string, width: number) => {
            return (str || '').toString().padEnd(width);
        };

        // Format each row with fixed widths
        const text = Array.from(validatedDevices.value)
            .map(
                (device) =>
                    `${padString(device.nLISID, columnWidths.nlisid)}${padString(
                        device.rFID,
                        columnWidths.rfid
                    )}${padString(device.species, columnWidths.species)}${padString(
                        device.status,
                        columnWidths.status
                    )}${padString(device.deceased ? 'YES' : 'NO', columnWidths.deceased)}`
            )
            .join('\n');

        const blob = new Blob([text], { type: 'text/plain;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute('download', `${consignmentNumber}-Devices.TXT`);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return {
        validatedDevices,
        rawManualDeviceIds,
        deviceIds,
        dropzoneRef,
        openDialog,
        acceptedFile,
        uploadErrorMessage,
        isApiError,
        setIsApiError,
        inDropZone,
        onDropRejected,
        onDropAccepted,
        onDragOver,
        onDragLeave,
        processFile,
        processManualDevices,
        clearValidatedDevices,
        clearFiles,
        validateAndAddDevices,
        revalidate,
        exportToPDF,
        exportToCSV,
        exportToTXT,
        transferDevices,
    };
};

export default useDeviceManager;
