type OwnerInfoProps = {
    text: string | undefined;
};

const OwnerInfo = ({ text }: OwnerInfoProps) => {
    return (
        <>
            <b className="owner">{text}</b>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .owner {
                    color: $color-owner();

                    @media (prefers-color-scheme: dark) {
                        color: $color-grey90();
                    }
                }
            `}</style>
        </>
    );
};

export default OwnerInfo;
