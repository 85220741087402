import { MovementActions, useMovementContext } from '@common/context/MovementContext';
import { DeviceTransferStatus, MovementStatus } from '@containers/Consignments/DeviceTransferHelper';
import { IUser } from '@typings';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { NlisAccountTypeEnum, RoleTypeEnum } from '@utils/enums';
import { isAfter, isBefore, parseISO, startOfDay } from 'date-fns';

import useRoleValidation from './useRoleValidation';

const useMovement = () => {
    const { movementActions, setMovementActions } = useMovementContext();
    const { hasRoleWithConsignment } = useRoleValidation();

    // Updates the movement actions for a specific key (consignment number) by merging with existing actions or creating new ones
    const updateMovementActions = (key: string, action: Partial<MovementActions>) => {
        setMovementActions((prev) => {
            // Ensure the previous state exists; if it's undefined, default to an empty object
            const previousState = prev || {};

            // Retrieve the existing actions for the given key or initialize default actions
            const existingActions = previousState[key] || {
                // canAddOrEditDevicesForReceiver: false,
                receiver: false,
                canAddOrEditDevicesForProducer: false,
                canPerformMovement: false,
                canPreviewMovement: false,
                canViewMovement: false,
                isAccountAuthorizedForMovement: false,
                movementStatus: DeviceTransferStatus.NotReady, // Default status
            };

            // Merge the existing actions with the new actions provided in the `action` parameter
            // This ensures that only the specified fields in `action` are updated
            const movementActions = {
                ...previousState,
                [key]: {
                    ...existingActions,
                    ...action,
                },
            };

            // Return the updated movement actions object to update the state
            return movementActions;
        });
    };

    const removeMovementActions = (consignmentNumber: string) => {
        setMovementActions((prev) => {
            if (!prev) return prev;

            // Create a copy of the previous state
            const newState = { ...prev };

            // Remove the entry for the deleted consignment
            delete newState[consignmentNumber];

            return newState;
        });
    };

    /**
     * Checks and updates movement actions based on the provided consignment and user details
     * @param consignment
     * @param user
     * @returns boolean
     */
    const checkMovementActions = (consignment: any, user: IUser) => {
        const canAddOrEditDevicesForProducer = checkCanAddOrEditDevicesForProducer(consignment, user);
        // const canAddOrEditDevicesForReceiver = checkCanAddOrEditDevicesForReceiver(consignment, user);
        const canPerformMovement = checkCanPerformMovement(consignment, user);
        const canPreviewMovement = checkCanPreviewMovement(consignment, user);
        const canViewMovement = checkCanViewMovement(consignment, user);
        const isAccountAuthorizedForMovement = checkIsApplicableAccountType(user.accountDetails.nlisAccountType);
        const isReceiver = !!user && hasRoleWithConsignment(user?.accountDetails, RoleTypeEnum.RECEIVER, consignment);
        const hasDevices = (Number(consignment?.numOfAddedDevices) ?? 0) > 0;

        const movementStatus = getDeviceTransferStatus(
            hasDevices,
            consignment.movementDate,
            consignment.status,
            consignment.deviceMovementStatus
        );

        const newValues = {
            canAddOrEditDevicesForProducer,
            // canAddOrEditDevicesForReceiver,
            isReceiver,
            canPerformMovement,
            canPreviewMovement,
            canViewMovement,
            isAccountAuthorizedForMovement,
            movementStatus,
        };
        // Check if values actually changed before updating
        const currentValues = movementActions?.[consignment.number];
        const hasChanges =
            !currentValues ||
            Object.keys(newValues).some(
                (key) => newValues[key as keyof typeof newValues] !== currentValues[key as keyof typeof currentValues]
            );
        if (hasChanges) {
            updateMovementActions(consignment.number, newValues);
        }
    };

    /**
     * Determines if a producer can add or edit devices for a consignment
     * @param consignment
     * @param user
     * @returns boolean
     */
    const checkCanAddOrEditDevicesForProducer = (consignment: any, user: IUser) => {
        /*
            To ADD/EDIT as a Producer user
            Must be LPA Producer
            And todays date is less than equal to movement date

            If DRAFT consignment, todays date can be after movement date
        */

        if (!consignment.movementDate) return false;

        const movementDate = parseISO(consignment.movementDate);
        const todaysDate = startOfDay(new Date());
        const isBeforeOrSameAsMovementDate = !isAfter(todaysDate, movementDate);

        const isProducer = !!user && hasRoleWithConsignment(user?.accountDetails, RoleTypeEnum.PRODUCER, consignment);
        const isLpaUser = user && user?.accountDetails.accountType === 'LPA';
        const isDraftConsignment = consignment.status === ConsignmentStatus.DRAFT;
        const hasMovementId = Boolean(consignment.deviceMovementID);
        if (!isProducer || !isLpaUser || hasMovementId) return false;

        return isDraftConsignment || isBeforeOrSameAsMovementDate;
    };

    /**
     * Determines if a user can perform the movement for a consignment
     * only user who can perform the movemnt can add, edit or remove devices
     * @param consignment
     * @param user
     * @returns boolean
     */
    const checkCanPerformMovement = (consignment: any, user: IUser) => {
        /*
            To perform transfer, add, edit or remove devices user
            Should be reciever
            And should be within transfer window
            And should be an applicable NLIS account type
        */

        if (!consignment.movementDate || !consignment.status || !user.accountDetails.nlisAccountType) return false;

        const movementDate = parseISO(consignment.movementDate);
        const isApplicableAccountType = checkIsApplicableAccountType(user.accountDetails.nlisAccountType);
        const isWithinTransferWindow = checkIsWithinTransferWindow(movementDate, consignment.status);
        const isReceiver = !!user && hasRoleWithConsignment(user?.accountDetails, RoleTypeEnum.RECEIVER, consignment);
        const hasMovementId = Boolean(consignment.deviceMovementID);
        const canPerformMovement = !hasMovementId && isReceiver && isApplicableAccountType && isWithinTransferWindow;

        return canPerformMovement;
    };

    /**
     * Determines if a user can preview a movement before it occurs
     * @param consignment
     * @param user
     * @returns boolean
     */
    const checkCanPreviewMovement = (consignment: any, user: IUser) => {
        /*
            To preview, user
            Must be reciever
            And date is less than equal to movement date
            And consignment should have devices
        */

        if (!consignment.movementDate) return false;

        const movementDate = parseISO(consignment.movementDate);
        const hasDevices = (consignment?.numOfAddedDevices ?? 0) > 0;
        const todaysDate = startOfDay(new Date());
        const isBeforeMovementDate = isBefore(todaysDate, movementDate);
        const isReceiver = !!user && hasRoleWithConsignment(user?.accountDetails, RoleTypeEnum.RECEIVER, consignment);

        const canPreviewMovement = isReceiver && isBeforeMovementDate && hasDevices;
        return canPreviewMovement;
    };

    /**
     * Check whether user can preview movement details for a consignment
     * @param consignment
     * @param user
     * @returns boolean
     */
    const checkCanViewMovement = (consignment: any, user: IUser) => {
        /*
            To view, user
            Must be Reciever/Producer
            And date is more than movement date (for source) || date is more than movement date + 7 days (for reciever)
            And consignment should have devices
        */

        if (!consignment.movementDate || !consignment.status) return false;

        const movementDate = parseISO(consignment.movementDate);
        const hasDevices = (consignment?.numOfAddedDevices ?? 0) > 0;

        const todaysDate = startOfDay(new Date());

        const isCurrentDateMoreThanMovementDate = isAfter(todaysDate, movementDate);
        const isConsignmentCompleted = consignment.status === ConsignmentStatus.LOCKED;

        const isReceiver = !!user && hasRoleWithConsignment(user?.accountDetails, RoleTypeEnum.RECEIVER, consignment);
        const isProducer = !!user && hasRoleWithConsignment(user?.accountDetails, RoleTypeEnum.PRODUCER, consignment);
        const isLpaUser = user && user?.accountDetails.accountType === 'LPA';
        const isApplicableAccountType = checkIsApplicableAccountType(user.accountDetails.nlisAccountType);
        const isDraftConsignment = consignment.status === ConsignmentStatus.DRAFT;
        const hasMovementId = Boolean(consignment.deviceMovementID);
        const canViewMovement =
            ((!hasMovementId && isReceiver && isConsignmentCompleted) ||
                (isProducer && isLpaUser && isCurrentDateMoreThanMovementDate && !isDraftConsignment) ||
                (isReceiver && !isApplicableAccountType)) &&
            hasDevices;

        return canViewMovement;
    };

    /**
     * Helper function to check if todays date is within the transfer window
     * @param movementDate
     * @param consignmentStatus
     * @returns boolean
     */
    const checkIsWithinTransferWindow = (movementDate: Date, consignmentStatus: ConsignmentStatus): boolean => {
        const todaysDate = startOfDay(new Date());
        return (
            todaysDate >= startOfDay(movementDate) &&
            consignmentStatus !== ConsignmentStatus.LOCKED &&
            consignmentStatus !== ConsignmentStatus.DRAFT
        );
    };

    /**
     * Helper function to check if a user's NLIS account type is applicable
     * @param userNlisAccountType
     * @returns boolean
     */
    const checkIsApplicableAccountType = (userNlisAccountType: any): boolean => {
        const applicableAccountTypes = [
            NlisAccountTypeEnum.FEEDLOT,
            NlisAccountTypeEnum.PRODUCER,
            NlisAccountTypeEnum.PROCESSOR,
        ];
        return applicableAccountTypes.includes(userNlisAccountType);
    };

    /**
     * Helper function  which determines the device transfer status based on various parameters such as movement date and consignment status
     * @param hasDevices
     * @param movementDate
     * @param consignmentStatus
     * @param movementStatus
     * @returns DeviceTransferStatus
     */
    const getDeviceTransferStatus = (
        hasDevices: boolean,
        movementDate: Date,
        consignmentStatus: ConsignmentStatus,
        movementStatus: MovementStatus
    ): DeviceTransferStatus => {
        if (movementStatus) {
            switch (movementStatus) {
                case MovementStatus.Pending:
                    return DeviceTransferStatus.Pending;
                case MovementStatus.Complete:
                    return DeviceTransferStatus.Transferred;
                case MovementStatus.Error:
                    return DeviceTransferStatus.Failed;
            }
        }

        const isWithinTransferWindow = checkIsWithinTransferWindow(movementDate, consignmentStatus);

        if (isWithinTransferWindow) {
            return hasDevices ? DeviceTransferStatus.ReadyWithDevices : DeviceTransferStatus.Ready;
        }

        if (consignmentStatus === ConsignmentStatus.LOCKED) {
            return DeviceTransferStatus.NotAvailable;
        }

        return hasDevices ? DeviceTransferStatus.NotReadyWithDevices : DeviceTransferStatus.NotReady;
    };

    return {
        removeMovementActions,
        checkMovementActions,
        checkIsApplicableAccountType,
        checkIsWithinTransferWindow,
        getDeviceTransferStatus,
    };
};

export default useMovement;
