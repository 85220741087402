import { ValidatedDeviceResponse } from '@common/context/DevicesContext';
import { MovementActions } from '@common/context/MovementContext';
import Button, { ButtonSize, ButtonType } from '@components/Button';
import Config from '@config';
import { DeviceResponseStatus } from '@utils/enums';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface ButtonConfig {
    text: string;
    type: ButtonType;
    onClick?: () => void;
    disabled?: boolean;
    size?: ButtonSize;
}

interface FooterActionButtonsProps {
    isMovementComplete: boolean;
    showDevicesList: boolean;
    actions: MovementActions;
    loading: boolean;
    replaceAllRef?: React.RefObject<any>;
    handleNextClick: () => void;
    navigateToSummary: () => void;
    isError: boolean;
    deviceIds: Set<string>;
    validatedDevices: ValidatedDeviceResponse;
    canValidateDevices: boolean;
    activeDeviceId: string | null;
    handleMoveToPic: () => void;
}

const FooterActionButtons: React.FC<FooterActionButtonsProps> = ({
    isMovementComplete,
    showDevicesList,
    actions,
    loading,
    replaceAllRef,
    handleNextClick,
    navigateToSummary,
    isError,
    deviceIds,
    validatedDevices,
    canValidateDevices,
    activeDeviceId,
    handleMoveToPic,
}) => {
    const history = useHistory();

    const isButtonDisabled = () => {
        if (
            actions?.canPreviewMovement ||
            actions?.canViewMovement ||
            (actions?.isReceiver && !actions?.isAccountAuthorizedForMovement) ||
            activeDeviceId !== null
        ) {
            return true;
        }
        return false;
    };

    const isAllDevicesValid = !Array.from(validatedDevices.value).every(
        (device) => device.status === DeviceResponseStatus.NOT_FOUND
    );

    const isNextDisable = () => {
        if (
            deviceIds.size > 0 &&
            !isError &&
            ((showDevicesList && actions?.canPerformMovement) || (!showDevicesList && canValidateDevices))
        ) {
            return false;
        }
        return true;
    };

    const getProducerButtons = (): ButtonConfig[] => [
        {
            text: 'Remove & replace devices',
            type: isButtonDisabled() ? 'disable-lite' : 'reset',
            disabled: isButtonDisabled(),
            onClick: () => replaceAllRef?.current?.show(),
        },
        {
            text: 'Back to summary consignment',
            type: activeDeviceId !== null ? 'disable' : 'primary',
            disabled: activeDeviceId !== null || loading,
            onClick: navigateToSummary,
        },
    ];

    const getReceiverButtons = (): ButtonConfig[] => [
        {
            text: 'Previous Page',
            type: activeDeviceId !== null ? 'disable' : 'secondary',
            disabled: activeDeviceId !== null,
            onClick: () => history.goBack(),
        },
        {
            text: 'Remove & replace devices',
            type: isButtonDisabled() ? 'disable-lite' : 'secondary',
            disabled: isButtonDisabled(),
            onClick: () => replaceAllRef?.current?.show(),
        },
        {
            text: 'Move onto your PIC',
            type: isButtonDisabled() || !isAllDevicesValid ? 'disable' : 'primary',
            disabled: loading || isButtonDisabled() || !isAllDevicesValid,
            onClick: handleMoveToPic,
        },
    ];

    const getMovementCompleteButtons = (): ButtonConfig[] => [
        {
            text: 'Previous Page',
            type: activeDeviceId !== null ? 'disable' : 'primary',
            disabled: activeDeviceId !== null || loading,
            onClick: () => history.goBack(),
        },
        {
            text: 'Print Receipt',
            type: 'secondary',
            disabled: loading,
            onClick: () => console.log('Printing receipt..'),
        },
        {
            text: 'Login to NLIS',
            type: 'primary',
            disabled: loading,
            onClick: () => window.open(Config.NLIS_URL, '_blank'),
        },
    ];

    const getUploadButtons = (): ButtonConfig[] => [
        {
            text: 'Previous Page',
            type: 'secondary',
            onClick: () => history.goBack(),
        },
        {
            text: 'Next step',
            type: isNextDisable() ? 'disable' : 'primary',
            disabled: loading || isNextDisable(),
            onClick: handleNextClick,
            size: 'medium',
        },
    ];

    const renderButtons = (buttons: ButtonConfig[]) => (
        <div className="flex-start-row flex-between">
            {buttons.map((button, index) => (
                <Button
                    key={`${button.text}-${index}`}
                    buttonType={button.type}
                    disabled={button.disabled}
                    onClick={button.onClick}
                    buttonSize={button.size || 'normal'}
                >
                    {button.text}
                </Button>
            ))}
        </div>
    );

    if (isMovementComplete) {
        return (
            <div className="flex-start-row flex-between">
                {renderButtons([getMovementCompleteButtons()[0]]).props.children}
                <div className="flex-end-row">
                    {renderButtons(getMovementCompleteButtons().slice(1)).props.children}
                </div>
            </div>
        );
    }

    if (!showDevicesList) {
        return renderButtons(getUploadButtons());
    }

    if (!actions?.isReceiver && (actions?.canAddOrEditDevicesForProducer || actions?.canViewMovement)) {
        return (
            <div className="flex-start-row flex-between">
                {renderButtons([getProducerButtons()[0]]).props.children}
                <div className="flex-end-row">{renderButtons(getProducerButtons().slice(1)).props.children}</div>
            </div>
        );
    }

    if (actions?.isReceiver || actions?.canPreviewMovement || actions?.canPerformMovement) {
        return (
            <div className="flex-start-row flex-between">
                {renderButtons([getReceiverButtons()[0]]).props.children}
                <div className="flex-end-row">{renderButtons(getReceiverButtons().slice(1)).props.children}</div>
            </div>
        );
    }

    return null;
};

export default React.memo(FooterActionButtons);
