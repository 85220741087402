import {
    DeviceTransferNotReadyIcon,
    DeviceTransferPendingIcon,
    DeviceTransferReadyIcon,
    DeviceTransferTransferredIcon,
    FailedIcon,
    InfoIcon,
    SuccessIcon,
} from '@assets/icons';
import { ValidatedDevice, ValidatedDeviceResponse } from '@common/context/DevicesContext';
import { MovementActions } from '@common/context/MovementContext';
import HoverableIcon from '@components/HoverableIcon';
import NLISLoginLink from '@components/NLISLoginLink';
import NLISSupportNumber from '@components/NLISSupportNumber';
import { mapConsignmentToDeviceSpecies } from '@utils/enum-transformers';
import { ConsignmentSpecies, DeviceResponseStatus, DeviceValidationStatus } from '@utils/enums';
import { ReactNode } from 'react';

import { ConsignmentDetailQueryResponse } from './__generated__/ConsignmentDetailQuery.graphql';

export const PIC_DECEASED = 'DECEASED';

export const ICON_COLORS = {
    GREEN: '#008A07',
    GREY: '#848A94',
    RED: '#CB3727',
    YELLOW: '#FDB714',
} as const;

export const enum MovementStatus {
    Pending = 'Pending',
    Complete = 'Complete',
    Warning = 'Warning',
    Error = 'Error',
}

export enum DeviceTransferStatus {
    Ready = 'Ready',
    ReadyWithDevices = 'ReadyWithDevices',
    NotReady = 'NotReady',
    NotReadyWithDevices = 'NotReadyWithDevices',
    Pending = 'Pending',
    Transferred = 'Transferred',
    NotAvailable = 'NotAvailable',
    Failed = 'Failed',
}

export type MovementCardDetail = {
    icon?: React.ReactNode;
    title?: string;
    description?: string | ReactNode;
    buttonText?: string;
    buttonAction?: () => void | Promise<void>;
    isTabletOrMobile?: boolean;
} | null;

export const DEVICE_TRANSFER_STATUS_MAP: Record<
    DeviceTransferStatus,
    { icon: React.ReactNode; text: string; className: string; description: string }
> = {
    [DeviceTransferStatus.Ready]: {
        icon: null,
        text: 'Ready',
        className: 'ready',
        description: 'The transfer is available starting today for the next 7 days. Please proceed during this time.',
    },
    [DeviceTransferStatus.ReadyWithDevices]: {
        icon: (
            <DeviceTransferReadyIcon
                className="icon-xxs"
                alt="Ready with tags"
            />
        ),
        text: 'Ready',
        className: 'ready',
        description:
            'Tags have been added by the sender and can be reviewed now. The transfer is available starting today for the next 7 days. Please proceed during this time.',
    },
    [DeviceTransferStatus.NotReady]: {
        icon: null,
        text: 'Not ready',
        className: 'not-ready',
        description:
            'NLIS devices have not been added yet, and the movement cannot be submitted until the movement date.',
    },
    [DeviceTransferStatus.NotReadyWithDevices]: {
        icon: (
            <DeviceTransferNotReadyIcon
                className="icon-xxs"
                alt="Not ready with tags"
            />
        ),
        text: 'Not ready',
        className: 'not-ready',
        description:
            'NLIS devices have been added to the consignments by the sender and can be previewed. NLIS movement cannot be submitted until the movement date.',
    },
    [DeviceTransferStatus.NotAvailable]: {
        icon: null,
        text: 'Not available',
        className: 'not-available',
        description: 'Transfer is unavailable as it has exceeded the movement date plus 7 days.',
    },
    [DeviceTransferStatus.Transferred]: {
        icon: (
            <DeviceTransferTransferredIcon
                className="icon-xxs"
                alt="transferred"
            />
        ),
        text: 'Transferred',
        className: 'transferred',
        description: 'Transfer successful. Please review the outcome.',
    },
    [DeviceTransferStatus.Pending]: {
        icon: (
            <DeviceTransferPendingIcon
                className="icon-xxs"
                alt="pending"
            />
        ),
        text: 'Pending',
        className: 'pending',
        description: 'NLIS movement is being processed',
    },
    [DeviceTransferStatus.Failed]: {
        icon: (
            <DeviceTransferTransferredIcon
                className="icon-xxs"
                alt="transferred"
                width={13}
                height={13}
            />
        ),
        text: 'Transferred',
        className: 'transferred',
        description: 'Transfer successful. Please review the outcome.',
    },
};

export const getValidationStatus = (
    device: ValidatedDevice,
    consignment: ConsignmentDetailQueryResponse['consignment']
): DeviceValidationStatus => {
    if (device.status === DeviceResponseStatus.NOT_FOUND) {
        return DeviceValidationStatus.ERROR;
    }
    if (
        doSpeciesMatch(device, consignment) ||
        isPicDeceased(device) ||
        doPicsMatch(device, consignment) ||
        isSaleyardPic(device) ||
        device?.deceased
    ) {
        return DeviceValidationStatus.WARNING;
    }

    return DeviceValidationStatus.VALIDATED;
};

export const getStatusInfo = ({ status, issueCount }: { status: DeviceValidationStatus; issueCount?: number }) => {
    switch (status) {
        case DeviceValidationStatus.ERROR:
            return {
                title: `Errors detected: A total of ${issueCount} found`,
                description: (
                    <>
                        Devices with errors cannot be moved onto the receiver's PIC. We recommend editing, replacing, or
                        deleting them. For help, contact NLIS Support at <NLISSupportNumber />.
                    </>
                ),
                icon: (
                    <HoverableIcon
                        width={23}
                        height={23}
                        Icon={FailedIcon}
                        defaultColor="#CB3727"
                        hoverColor="#943B2E"
                    />
                ),
                tooltip: "Validation found errors, this device won't transfer. Please replace or delete it.",
            };
        case DeviceValidationStatus.WARNING:
            return {
                title: `Warnings detected: A total of ${issueCount} found`,
                description: (
                    <>
                        Some devices have warnings but can still be moved onto the receiver's PIC. We recommend
                        reviewing and correcting them on {<NLISLoginLink />}. For help, contact NLIS Support at{' '}
                        <NLISSupportNumber />.
                    </>
                ),
                icon: (
                    <HoverableIcon
                        Icon={InfoIcon}
                        defaultColor="#FDB714"
                        hoverColor="#BA8827"
                    />
                ),
                tooltip: 'Validation has some warnings. This device can be transferred, but resolving it is helpful.',
            };
        case DeviceValidationStatus.VALIDATED:
        default:
            return {
                title: 'Good news: No issues detected',
                description: (
                    <>
                        All devices have been successfully validated with no errors or warnings. From the movement date,
                        the receiver can accept and move them onto their PIC.
                    </>
                ),
                icon: (
                    <HoverableIcon
                        Icon={SuccessIcon}
                        defaultColor="#008A07"
                        hoverColor="#16704A"
                    />
                ),
                tooltip:
                    'Validation completed successfully with no errors, or warnings. All in order, allowing you to proceed smoothly with the transfer.',
            };
    }
};

export const doSpeciesMatch = (
    validatedDevice: ValidatedDevice,
    consignment: ConsignmentDetailQueryResponse['consignment']
) => {
    if (!validatedDevice.species) {
        return false;
    }
    return (
        validatedDevice?.species?.toLowerCase() !==
        mapConsignmentToDeviceSpecies(consignment?.species! as ConsignmentSpecies).toLowerCase()
    );
};
export const doPicsMatch = (
    validatedDevice: ValidatedDevice,
    consignment: ConsignmentDetailQueryResponse['consignment']
) => {
    if (!validatedDevice.registeredTo) {
        return false;
    }
    return validatedDevice?.registeredTo !== consignment?.origin.pic;
};
export const isSaleyardPic = (validatedDevice: ValidatedDevice) => {
    if (!validatedDevice.registeredTo) {
        return false;
    }
    return validatedDevice.registeredTo?.startsWith('EUSY');
};

export const isPicDeceased = (validatedDevice: ValidatedDevice) => {
    if (!validatedDevice.registeredTo) {
        return false;
    }
    return validatedDevice.registeredTo === PIC_DECEASED;
};

export const isOptionsDisabled = (actions: MovementActions) => {
    if (
        actions?.canPreviewMovement ||
        actions?.canViewMovement ||
        (actions?.isReceiver && !actions.isAccountAuthorizedForMovement)
    ) {
        return true;
    }
    return false;
};

export const isValidSpeciesForMovement = (consignment: ConsignmentDetailQueryResponse['consignment']) => {
    return consignment?.species === ConsignmentSpecies.CATTLE || consignment?.species === ConsignmentSpecies.SHEEP_LAMB;
};

export const showTotalNlisDevices = (
    consignment: ConsignmentDetailQueryResponse['consignment'],
    validatedDevices: ValidatedDeviceResponse
) => {
    return (
        isValidSpeciesForMovement(consignment) &&
        !(
            Number(consignment?.numOfNlisDevices ?? 0) + Number(consignment?.numOfRumenDevices ?? 0) ===
            Number(validatedDevices.totalDevices ?? 0)
        )
    );
};
