import { InfoIcon } from '@assets/icons';
import DragDropUpload from '@components/DragNDropUpload';
import Tooltip from '@components/Form/Tooltip';
import classNames from 'classnames';

import ManualDeviceInput from './ManualDeviceInput';
interface AddNlisDevicesProps {
    activeTab: number;
    onTabSwitch: (index: number) => void;
}
export const AddOrUploadNlisDevices: React.FC<AddNlisDevicesProps> = ({ activeTab, onTabSwitch }) => {
    const DeviceExamples = () => (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';
                    @import 'mixins';
                    .example-container {
                        width: 30%;
                        min-width: 230px;
                        .header {
                            background-color: $unselected-tab-background;
                            padding: 5px 10px;
                            width: 100%;
                            h4 {
                                font-weight: 600;
                            }
                        }
                    }
                    .icon-info {
                        color: $info-icon;
                    }
                `}
            </style>

            <div className="flex-start-column example-container">
                <div className="header">
                    <h4>Device examples</h4>
                </div>
                <div
                    className="flex-center-row flex-between m-v-12 p-h-12"
                    style={{ width: '100%' }}
                >
                    <p className="small">NLISID: 3ABCD123XBK00611</p>
                    <div className="icon-info">
                        <Tooltip
                            title="What is NLISID?"
                            text="The NLIS ID is a 16-character unique code printed onto each device, containing the PIC, manufacturer, species, and year letter, plus a unique serial number. It can be read visually if you don't have a eID tag reader."
                            children={<InfoIcon />}
                        />
                    </div>
                </div>
                <div
                    className="flex-center-row flex-between p-h-12"
                    style={{ width: '100%' }}
                >
                    <p className="small">RFID: 900 012345678993</p>
                    <div className="icon-info">
                        <Tooltip
                            title="What is RFID?"
                            text="The RFID is a unique 15-digit number coded onto each device. To retrieve it, scan the device - most commonly an ear tag - using an eID tag reader."
                            children={<InfoIcon />}
                        />
                    </div>
                </div>
            </div>
        </>
    );
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .summary-panel {
                    flex: 2 0 65%;

                    .date-info {
                        gap: 10px;
                    }

                    .to-details {
                        border-left: 1px solid $grey-border;
                        position: relative;
                    }
                }

                .box {
                    box-sizing: border-box;
                    background-color: $color-white;
                    border: 1px solid $grey-border;
                    border-radius: 4px;
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .horizontal-line {
                    border-top: 1px solid $color-border; /* Creates a black 1px wide line */
                    margin: 15px 0; /* Optional: Add some spacing around the line */
                }

                .mainContainer {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin-top: 50px;
                }
                .child-container {
                    width: 100%;
                }
                .tabs-div {
                    display: flex;
                    gap: 10px;
                }
                .child-container-tab {
                    background-color: #f2f2f2;
                    border: 2px solid $color-border;
                    border-bottom: 0px;
                    padding: 15px;
                    border-radius: 5px 5px 0px 0px;
                    cursor: pointer;

                    .tabs-label {
                        color: $unselected-tab-text;
                    }

                    &.selected {
                        border: 2px solid $color-secondary;
                        background-color: white;
                        border-bottom: 0px;
                        .tabs-label {
                            color: $color-secondary;
                        }
                    }
                }
                .child-container-content {
                    background-color: white;
                    padding: 40px;
                    border-top: 2px solid $color-secondary;
                }

                .iconss {
                    color: $upload-file;
                }
                .upload-container {
                    width: 70%;
                    min-width: 300px;
                }
            `}</style>
            <div className="summary-panel box  m-v-32 p-h-16">
                <div className="panel-heading flex-row flex-between p-v-16">
                    <div>
                        <h2>Add NLIS Devices</h2>
                    </div>
                </div>
                <div className="horizontal-line"></div>
                <div className="mainContainer">
                    <div className="child-container">
                        <div className="tabs-div">
                            <div
                                className={classNames('child-container-tab', {
                                    selected: activeTab === 0,
                                })}
                                onClick={() => onTabSwitch(0)}
                            >
                                <h3 className="tabs-label">Upload your File</h3>
                            </div>
                            <div
                                className={classNames('child-container-tab', {
                                    selected: activeTab === 1,
                                })}
                                onClick={() => onTabSwitch(1)}
                            >
                                <h3 className="tabs-label">Add Manually</h3>
                            </div>
                        </div>
                        <div className="child-container-content p-h-32">
                            {/* <AddDevice activeTab={activeTab} /> */}
                            <div>
                                <h2>
                                    {activeTab === 0
                                        ? 'Upload File With Devices'
                                        : 'Please type or paste NLIS devices into the box below'}
                                </h2>
                                <div className="m-v-12">
                                    {activeTab === 0 ? (
                                        <>
                                            <p>
                                                Upload a file with devices, such as NLISIDs or RFIDs ear tags (or rumen
                                                devices for cattle), to pre-fill the details.
                                            </p>{' '}
                                            <p>
                                                Ensure the file is in CSV or TXT format, with all devices organised in a
                                                one column format.
                                            </p>{' '}
                                            <p>
                                                If you need help with this, check out our{' '}
                                                <a
                                                    href="#/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Help Guide
                                                </a>
                                                .
                                            </p>{' '}
                                            <p className="m-v-20">
                                                Proceeding to the next step will submit all the details.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                You can add devices, such as NLISIDs or RFIDs ear tags (or rumen devices
                                                for cattle), one by one or paste multiple numbers at once.
                                            </p>
                                            <p>
                                                Press 'enter' after each tag. Add more as needed, or click 'Reset' to
                                                start over.
                                            </p>
                                            <p>
                                                If you need help with this, check out our{' '}
                                                <a
                                                    href="#/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Help Guide
                                                </a>
                                                .
                                            </p>{' '}
                                            <p className="m-v-20">
                                                Proceeding to the next step will submit all the details.
                                            </p>
                                        </>
                                    )}
                                </div>
                                {activeTab === 0 ? (
                                    <DragDropUpload children={<DeviceExamples />} />
                                ) : (
                                    <ManualDeviceInput children={<DeviceExamples />} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
