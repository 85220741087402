import { DateIcon } from '@assets/icons';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import MovingToFromSummary from '@containers/Consignments/components/MovingToFromSummary';
import React from 'react';

export interface MovementToFromSectionProps {
    consignment: ConsignmentDetailQueryResponse['consignment'];
}

const DevicesMovementToFromSection: React.FC<MovementToFromSectionProps> = ({ consignment }) => {
    return (
        <>
            <style jsx>
                {`
                    @import 'mixins';
                    @import 'vars';
                    @import 'layout';

                    .summary-panel {
                        flex: 2 0 65%;
                    }

                    .box {
                        box-sizing: border-box;
                        background-color: $color-white;
                        border: 1px solid $grey-border;
                        border-radius: 4px;
                        padding: 16px;
                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-white, 80%);
                        }
                    }

                    .grey-text {
                        color: $color-subtitle;
                        cursor: inherit;
                    }

                    .bold {
                        font-weight: 600;
                        margin: 0;
                    }

                    .info-section {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 10px;
                    }

                    .date-info {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        minwidth: content;
                    }

                    .horizontal-line {
                        border-top: 1px solid $color-border;
                        margin: 15px 0;
                    }

                    .info-text {
                        width: 54%;
                    }
                `}
            </style>
            <div>
                <div className="summary-panel box m-v-32">
                    <h2>NLIS Movement Details</h2>

                    <div
                        className="info-section flex-row flex-between"
                        style={{ alignItems: 'flex-start' }}
                    >
                        <div className="info-text">
                            Before completing the NLIS transfer, please review the NLIS movement details, including
                            where the livestock are coming from, where they are moving to, the movement date, and the
                            livestock details.
                        </div>
                        {(consignment?.movementDate as any) && (
                            <div className="date-info">
                                <DateIcon />
                                <label className="info-label grey-text">Movement Date</label>
                                <p className="bold">{new Date(consignment?.movementDate as any).toMLADateString()}</p>
                            </div>
                        )}
                    </div>

                    <div className="horizontal-line" />
                    <MovingToFromSummary consignment={consignment} />
                </div>
            </div>
        </>
    );
};

export default DevicesMovementToFromSection;
