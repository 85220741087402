import { CheckIcon, ChevronDownIcon, ClearIcon, WarningIcon } from '@assets/icons';
import { ValidatedDevice } from '@common/context/DevicesContext';
import Button from '@components/Button';
import ContextMenu from '@components/Form/ContextMenu';
import Input from '@components/Form/Input';
import Tooltip from '@components/Form/Tooltip';
import Modal from '@components/Modal';
import Tag from '@components/Tag';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import ConfirmModal, { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import {
    doPicsMatch,
    doSpeciesMatch,
    getStatusInfo,
    getValidationStatus,
    isPicDeceased,
    isSaleyardPic,
} from '@containers/Consignments/DeviceTransferHelper';
import GetDeviceContextMenu, { OPTIONS } from '@containers/Devices/Device-Context-Menu';
import useDeviceManager from '@effects/useDeviceManager';
import { deviceStatusEnumToColor, deviceStatusEnumToName } from '@utils/enum-transformers';
import { DeviceResponseStatus, DeviceWarningMessage, ValidationApiAction } from '@utils/enums';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

interface DeviceRowProps {
    validatedDevice: ValidatedDevice;
    consignment: ConsignmentDetailQueryResponse['consignment'];
    setIsDropdown: (isOpen: boolean) => void;
    optionsDisable: boolean;
    isDuplicatedDeviceId: (key: 'nLISID' | 'rFID', deviceId: string) => boolean;
}
type WarningColumnHeader = 'species' | 'registeredTo';

const DeviceRow: React.FC<DeviceRowProps> = ({
    validatedDevice,
    consignment,
    setIsDropdown,
    optionsDisable,
    isDuplicatedDeviceId,
}) => {
    const { validateAndAddDevices, validatedDevices } = useDeviceManager();
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [optionState, setOptionState] = useState('');
    const deleteModalRef = useRef<ConfirmModalRef>();
    const [updatedDeviceId, setUpdatedDeviceId] = useState<string[]>([]);

    useEffect(() => {
        // Close options when device becomes disabled
        if (optionState !== '') {
            setIsValid(
                !Array.from(validatedDevices.value).some(
                    (device) => device.nLISID === updatedDeviceId.join() || device.rFID === updatedDeviceId.join()
                )
            );
            if (optionsDisable) {
                setOptionState('');
            }
        }
    }, [
        optionState,
        optionsDisable,
        updatedDeviceId,
        validatedDevice.nLISID,
        validatedDevice.rFID,
        validatedDevices.value,
    ]);

    const handleOptionChange = (isOpen: boolean) => {
        setIsDropdown(isOpen);
        if (!isOpen) {
            setOptionState('');
        }
    };

    const WarningText = (columnHeader: WarningColumnHeader) => {
        // Warning message determination using a map of conditions
        const warningConditions: {
            checkField: WarningColumnHeader;
            condition: () => boolean;
            message: DeviceWarningMessage;
        }[] = [
            {
                checkField: 'registeredTo',
                condition: () => isSaleyardPic(validatedDevice),
                message: DeviceWarningMessage.SALEYARD,
            },
            {
                checkField: 'registeredTo',
                condition: () => isPicDeceased(validatedDevice),
                message: DeviceWarningMessage.DECEASED,
            },
            {
                checkField: 'registeredTo',
                condition: () => doPicsMatch(validatedDevice, consignment),
                message: DeviceWarningMessage.NO_MATCH,
            },
            {
                checkField: 'species',
                condition: () => doSpeciesMatch(validatedDevice, consignment),
                message: DeviceWarningMessage.WRONG_SPECIES,
            },
        ];
        // Filter warnings based on type
        const relevantWarnings = warningConditions.filter((warning) => {
            if (columnHeader === 'registeredTo') return warning.checkField === 'registeredTo';
            if (columnHeader === 'species') return warning.checkField === 'species';
            return false;
        });

        // Find first matching warning where the field exists and condition is true
        const matchingWarning = relevantWarnings.find(
            (warning) => validatedDevice[warning.checkField] && warning.condition()
        );

        return matchingWarning ? matchingWarning.message : null;
    };

    const DeviceIdNotFoundErrorText = () => {
        if (validatedDevice.status.toLowerCase() === DeviceResponseStatus.NOT_FOUND.toLowerCase()) {
            return 'INVALID ID, PLEASE RECHECK';
        }
        return null;
    };

    const getClassName = (warning: boolean, error: boolean) => {
        return classNames('cell-content"', {
            'cell-content--Warning': warning,
            'cell-content--Inactive': !warning && validatedDevice.status === DeviceResponseStatus.INACTIVE,
            'cell-content--Error': error,
        });
    };

    const handleSave = async () => {
        setIsDropdown(false);
        setLoading(true);
        await validateAndAddDevices(consignment?.number as string, ValidationApiAction.EDIT, updatedDeviceId, [
            validatedDevice.nLISID ?? validatedDevice.rFID,
        ]);
        setLoading(false);
        setOptionState('');
    };

    const validationStatus = getValidationStatus(validatedDevice, consignment);

    const { tooltip, icon } = getStatusInfo({ status: validationStatus });

    const deviceContextMenu = GetDeviceContextMenu(validatedDevice, setOptionState, deleteModalRef, setUpdatedDeviceId);

    return (
        <div
            className={classNames('tbl-row')}
            key={validatedDevice?.nLISID ?? validatedDevice?.rFID}
        >
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .tbl-row {
                    .tbl-cell-30 {
                        div {
                            width: 100%;
                        }
                    }

                    @media (max-width: $md-max) {
                        border: 1px solid $color-line;
                        border-radius: $border-radius;
                        background: $color-white;
                        margin: grid(4) 0;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 80%);
                        }
                    }
                }

                .tbl-unsupported {
                    // background-color: $color-warn;
                }

                .title {
                    :global(.Button--Link) {
                        text-align: left;
                    }
                }

                .cell-content {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-content: flex-start;
                    :global(.Button--Link) {
                        justify-content: flex-start;
                        margin-top: grid(1);

                        :global(svg) {
                            width: grid(4);
                            height: grid(4);
                        }
                    }
                    &--Warning {
                        h4,
                        p {
                            color: rgba($color-warn, 1);
                        }
                        h4 {
                            font-weight: 500;
                        }
                        p {
                            font-size: 10px;
                        }
                    }
                    &--Error {
                        h4,
                        p {
                            color: rgba($color-error, 1);
                        }
                        h4 {
                            font-weight: 500;
                        }
                        p {
                            font-size: 10px;
                        }
                    }
                    &--Inactive {
                        h4,
                        p {
                            color: rgba($color-subtitle, 1);
                        }
                    }
                }

                .responsive-heading {
                    @media (max-width: $md-max) {
                        flex-direction: column;
                        align-items: flex-start !important;

                        .mobile-header-options {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                        }
                    }
                }

                .save-btn {
                    color: $color-secondary;
                    font-size: 17px;
                    line-height: 20px;
                    font-weight: 500;
                }
                .check-icon {
                    color: $color-secondary;
                }
                .check-icon:hover {
                    cursor: pointer;
                }

                .warning-icon {
                    :global(svg) {
                        width: $icon-lg;
                        height: $icon-lg;
                        color: $color-error;
                    }
                }

                .gap {
                    gap: 10px;
                }
            `}</style>

            <div
                className="tbl-cell-10 responsive-heading flex-center-row flex-center"
                data-cy="device-validation"
            >
                <Tooltip
                    text={tooltip}
                    children={icon}
                    placement="right"
                />
            </div>

            <div
                className="tbl-cell-20"
                data-cy="device-nlisd"
            >
                <div className="cell-title">Device Nlisid</div>
                <div className={getClassName(false, Boolean(validatedDevice.nLISID && DeviceIdNotFoundErrorText()))}>
                    {optionState !== OPTIONS.EDIT_NLISID ? (
                        <>
                            <h4>{validatedDevice.nLISID}</h4>
                            <p>{validatedDevice.nLISID && DeviceIdNotFoundErrorText()}</p>
                        </>
                    ) : (
                        <Input
                            onChange={(e) => {
                                setUpdatedDeviceId([e.target.value.toString()]);
                                setIsValid(!isDuplicatedDeviceId('nLISID', e.target.value.toString()));
                            }}
                            errorText="ID ALREADY EXISTS"
                            isValid={isValid}
                            errorIcon={false}
                            inputSize="small"
                            value={updatedDeviceId.join()}
                        />
                    )}
                </div>
            </div>
            <div
                className="tbl-cell-20"
                data-cy="device-rfid"
            >
                <div className="cell-title">Device Rfid</div>
                <div className={getClassName(false, Boolean(validatedDevice.nLISID && DeviceIdNotFoundErrorText()))}>
                    {optionState !== OPTIONS.EDIT_RFID ? (
                        <>
                            <h4>{validatedDevice.rFID}</h4>
                            <p>{validatedDevice.rFID && DeviceIdNotFoundErrorText()}</p>
                        </>
                    ) : (
                        <Input
                            onChange={(e) => {
                                setUpdatedDeviceId([e.target.value.toString()]);
                                setIsValid(!isDuplicatedDeviceId('rFID', e.target.value.toString()));
                            }}
                            errorText="ID ALREADY EXISTS"
                            isValid={isValid}
                            errorIcon={false}
                            inputSize="small"
                            value={updatedDeviceId.join()}
                        />
                    )}
                </div>
            </div>
            <div
                className="tbl-cell-15"
                data-cy="device-livestock"
            >
                <div className="cell-title">Device Livestock</div>
                <div className={getClassName(Boolean(WarningText('species')), false)}>
                    <h4>{validatedDevice.species}</h4>
                    <p>{WarningText('species')}</p>
                </div>
            </div>
            <div
                className="tbl-cell-15"
                data-cy="device-registered-pic"
            >
                <div className="cell-title">Device Registered To</div>
                <div className={getClassName(Boolean(WarningText('registeredTo')), false)}>
                    <h4>{validatedDevice.registeredTo}</h4>
                    <p>{WarningText('registeredTo')}</p>
                </div>
            </div>
            <div
                className="tbl-cell-15"
                data-cy="device-status"
            >
                <div className="cell-title">Device Status</div>
                <div className="cell-content">
                    <Tag
                        checkIcon={false}
                        tagType={deviceStatusEnumToColor(validatedDevice.status as DeviceResponseStatus)}
                        text={deviceStatusEnumToName(validatedDevice.status as DeviceResponseStatus)}
                    />
                </div>
            </div>
            <div
                className="tbl-cell-10"
                data-cy="device-deceased"
            >
                <div className="cell-title">Device Deceased</div>
                <div className={getClassName(validatedDevice.deceased, false)}>
                    <h4>
                        {validatedDevice.status !== DeviceResponseStatus.NOT_FOUND &&
                            (validatedDevice.deceased ? 'YES' : 'NO')}
                    </h4>
                </div>
            </div>
            <div
                className="tbl-cell-15"
                data-cy="options"
            >
                {optionState !== OPTIONS.EDIT_NLISID && optionState !== OPTIONS.EDIT_RFID ? (
                    <ContextMenu
                        options={deviceContextMenu}
                        icon={<ChevronDownIcon />}
                        buttonText="Options"
                        buttonDisabled={optionsDisable}
                        onShowChange={handleOptionChange}
                    />
                ) : (
                    <div>
                        <Button
                            buttonType={!isValid ? 'tertiary-disable' : 'tertiary'}
                            buttonSize={'small'}
                            onClick={handleSave}
                            disabled={!isValid || loading}
                        >
                            <Tooltip
                                text="Save"
                                children={<CheckIcon />}
                                placement="bottom"
                            />
                        </Button>
                        <Button
                            buttonType={'tertiary'}
                            buttonSize={'small'}
                            onClick={() => handleOptionChange(false)}
                            disabled={loading}
                        >
                            <Tooltip
                                text="Cancel"
                                children={<ClearIcon />}
                                placement="bottom"
                            />
                        </Button>
                    </div>
                )}
            </div>
            <ConfirmModal
                actions={[
                    {
                        style: 'secondary',
                        text: 'Cancel',
                        buttonSize: 'full-width',
                        action: async () => {
                            setIsDropdown(false);
                            deleteModalRef.current?.hide();
                        },
                    },
                    {
                        style: 'delete',
                        text: 'Delete',
                        buttonSize: 'full-width',
                        action: async () => {
                            setIsDropdown(false);
                            setLoading(true);
                            await validateAndAddDevices(
                                consignment?.number as string,
                                ValidationApiAction.DELETE,
                                undefined,
                                [validatedDevice.nLISID ?? validatedDevice.rFID]
                            );
                            setLoading(false);
                            deleteModalRef.current?.hide();
                        },
                    },
                ]}
                ref={deleteModalRef}
                modalId={`confirm-modal`}
            >
                <div className="flex-center-row warning-icon gap">
                    <WarningIcon />
                    <h2>Are you sure you want to delete this device?</h2>
                </div>
                <div className="m-t-20">
                    <p>
                        By selecting <b>"Delete"</b>, the device will be removed and will no longer appear on your
                        consignment.{' '}
                    </p>
                    <p className="m-t-12">Are you sure you want to proceed with this action?</p>
                </div>
            </ConfirmModal>
            <Modal
                id="loader"
                show={loading}
                loader={loading}
            />
        </div>
    );
};

export default DeviceRow;
