import { HELPDESK_NUMBER } from '@utils/constants';
import React from 'react';

interface TelephoneNumberProps {
    number: string;
}

const TelephoneNumber: React.FC<TelephoneNumberProps> = ({ number }) => <a href={`tel:${number}`}>{number}</a>;

const NLISSupportNumber: React.FC = () => <TelephoneNumber number={HELPDESK_NUMBER} />;

export default NLISSupportNumber;
